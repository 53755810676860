export const customFormula = {
  revenue_focus_commodity: "#2 * #3 * #4",
  focus_commodity_cost_of_production:
    "( ( #5 * #2 ) + ( #26 * #3 * #2 ) ) * -1",
};

/**
 * NOTE
 * Focus Income formula ( ( #2 * #3 * #4 ) + ( #40 * #41 * #42 ) ) - ( ( #5 * #2 ) + ( #26 * #3 * #2 ) + ( #43 * #40 ) )
 * Target = 9001,
 * Diversified = 9002
 * i = ( a * p * v ) - ( cop * a ) + di
 */
export const yAxisFormula = {
  "#2": "( #9002 - #9001 ) / ( ( #5 + ( #26 * #3 ) + #43 ) - ( ( #4 * #3 ) + ( #42 * #41 ) ) )", // area
  "#3": "( #9001 - ( ( #5 * #2 ) + ( #26 * #3 * #2 ) + ( #43 * #40 ) ) + #9002 ) / ( ( #4 * #2 ) + ( #42 * #40 ) )", // volume
  "#4": "( #9001 - ( ( #5 * #2 ) + ( #26 * #3 * #2 ) + ( #43 * #40 ) ) + #9002 ) / ( ( #3 * #2 ) + ( #41 * #40 ) )", // price
  "#5": "( #9001 - ( ( #2 * #4 * #3 ) + ( #40 * #42 * #41 ) ) + #9002 ) / ( #2 + #40 )", // CoP for crop
  "#26":
    "( #9001 - ( ( #2 * #4 * #3 ) + ( #40 * #42 * #41 ) ) + #9002 ) / ( ( #3 * #2 ) + ( #41 * #40 ) )", // CoP for aqua
  "#9002":
    "( #9001 - ( ( #2 * #4 * #3 ) + ( #40 * #42 * #41 ) ) + ( ( #5 * #2 ) + ( #26 * #3 * #2 ) + ( #43 * #40 ) ) )", // diversified income for all question type
  "#40":
    "( #9002 - #9001 ) / ( ( #5 + ( #26 * #3 ) + #43 ) - ( ( #4 * #3 ) + ( #42 * #41 ) ) )", // animals (area)
  "#41":
    "( #9001 - ( ( #5 * #2 ) + ( #26 * #3 * #2 ) + ( #43 * #40 ) ) + #9002 ) / ( ( #4 * #2 ) + ( #42 * #40 ) )", // volume for animals
  "#42":
    "( #9001 - ( ( #5 * #2 ) + ( #26 * #3 * #2 ) + ( #43 * #40 ) ) + #9002 ) / ( ( #3 * #2 ) + ( #41 * #40 ) )", // price for animals
  "#43":
    "( #9001 - ( ( #2 * #4 * #3 ) + ( #40 * #42 * #41 ) ) + #9002 ) / ( #2 + #40 )", // CoP for animals
};
